import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { CampoVisible } from 'src/app/interfaces/campoVisible.interface';
import { Cliente } from 'src/app/interfaces/cliente.interface';
import { Documento } from 'src/app/interfaces/documento.interface';
import { FormasPago } from 'src/app/interfaces/formasPago.interface';
import { Mensaje } from 'src/app/interfaces/mensaje.interface';
import { Pais } from 'src/app/interfaces/pais.interface';
import { Provincia } from 'src/app/interfaces/provincia.interface';
import { TiposIvaCliente } from 'src/app/interfaces/tiposIvaCliente.interface';
import { CamposVisiblesService } from 'src/app/services/campos-visibles.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { FormasPagoService } from 'src/app/services/formas-pago.service';
import { MisFuncionesService } from 'src/app/services/mis-funciones.service';
import { PaisesService } from 'src/app/services/paises.service';
import { ProvinciasService } from 'src/app/services/provincias.service';
import { RegimenIvaService } from 'src/app/services/regimen-iva.service';
import { TabDirective } from 'src/app/shared/directives/tab.directive';
import { environment } from 'src/environments/environment';

interface TipoDocumento {
   codigo: string,
   descripcion: string
}

interface tiposParentesco {
   codigo: string,
   descripcion: string
}

@Component({
   selector: 'app-nuevo-cliente',
   templateUrl: './nuevo-cliente.component.html',
   styleUrls: ['./nuevo-cliente.component.css']
})
export class NuevoClienteComponent implements OnInit {

   @Output() onCodigo: EventEmitter<string> = new EventEmitter();
   @Output() onCancel: EventEmitter<number> = new EventEmitter();
   @Output() onCif: EventEmitter<string> = new EventEmitter();


   @ViewChild('codigo') domCodigo!: ElementRef;
   @ViewChild('bancoSucursal') domBancoSucursal!: ElementRef;
   @ViewChild('bancoDigitoControl') domBancoDigitoControl!: ElementRef;
   @ViewChild('bancoCuenta') domBancoCuenta!: ElementRef;
   @ViewChild('bancoBic') domBancoBic!: ElementRef;

   // NECESARIO PARA ACTIVAR TABULADOR
   @ViewChildren(TabDirective) inputs!: QueryList<TabDirective>;

   // VARIABLES DE COMPONENTES  
   public mensaje: Mensaje = {};
   public activarSpinner: boolean = false;
   public activarBuscador: boolean = false;
   public activarOcr: boolean = false;
   public volverA: string = '';
   public paginaActiva: number = 1;
   public rows: number = 200;
   public documento: Documento = {};
   public verOcr: boolean = environment.moduloOcr;
   public verFichasColor: boolean = environment.moduloPeluqueria;

   // VARIABLES DE COMBO  
   public provincias: Provincia[] = [];
   public provinciaActiva: string = "Provincia";
   public paises: Pais[] = [];


   public sexos: string[] = ['', 'F', 'M'];
   public sexoActivo: string = this.sexos[0];

   public tiposDocumento: TipoDocumento[] = [
      {
         codigo: 'E',
         descripcion: 'CIF'
      },
      {
         codigo: 'D',
         descripcion: 'DNI'
      },
      {
         codigo: 'P',
         descripcion: 'Pasaporte'
      },
      {
         codigo: 'C',
         descripcion: 'P. Conducir'
      },
      {
         codigo: 'I',
         descripcion: 'Carta o Doc Identidad'
      },
      {
         codigo: 'N',
         descripcion: 'P. Residencia Español'
      },
      {
         codigo: 'X',
         descripcion: 'P. Residencia C.E.'
      },
   ];
   public tiposParentesco: tiposParentesco[] = [
      {
         codigo: '',
         descripcion: ''
      },
      {
         codigo: 'AB',
         descripcion: 'Abuelo/a'
      },
      {
         codigo: 'BA',
         descripcion: 'Bisabuelo/a'
      },
      {
         codigo: 'BN',
         descripcion: 'Bisnieto/a'
      },
      {
         codigo: 'CD',
         descripcion: 'Cuñado/a'
      },
      {
         codigo: 'CY',
         descripcion: 'Cónyuge'
      },
      {
         codigo: 'HJ',
         descripcion: 'Hijo/a'
      },
      {
         codigo: 'HR',
         descripcion: 'Hermano/a'
      },
      {
         codigo: 'NI',
         descripcion: 'Nieto/a'
      },
      {
         codigo: 'PM',
         descripcion: 'Padre o madre'
      },
      {
         codigo: 'SB',
         descripcion: 'Sobrino/a'
      },
      {
         codigo: 'SG',
         descripcion: 'Suegro/a'
      },
      {
         codigo: 'TI',
         descripcion: 'Tío/a'
      },
      {
         codigo: 'YN',
         descripcion: 'Yerno o nuera'
      },
      {
         codigo: 'TU',
         descripcion: 'Tutor/a'
      },
      {
         codigo: 'OT',
         descripcion: 'Otro'
      },
   ];
   public tipoParentescoActivo: tiposParentesco = this.tiposParentesco[0];

   public tipoDocumentoActivo: TipoDocumento = this.tiposDocumento[1];

   public camposVisibles: CampoVisible[] = [];

   public formasPago: FormasPago[] = [];
   public formaPagoActiva: number = 1;

   public tiposIvaCliente: TiposIvaCliente[] = [];
   public tipoIvaClienteActivo: number = 1;

   public clientes: Cliente[] = [];
   // public facturarAActivo: Cliente = {};

   // VARIABLES DEL FORMULARIO
   public idActivo: number = 0;
   public clienteActivo: Cliente = {};
   public activarClienteFijo: boolean = environment.activarClienteFijo;


   public formulario: FormGroup = this.fb.group({
      codigo: ['', Validators.required],
      nombreFiscal: ['', [Validators.required, Validators.minLength(4)]],
      nombreComercial: [''],
      nombre: [''],
      apellido1: [''],
      apellido2: [''],
      tipoDocumento: [''],
      cif: ['', [Validators.required]],
      direccion: [''],
      codigoPostal: [''],
      localidad: [''],
      pais: [''],
      contacto: [''],
      tlfPrincipal: [''],
      emailPrincipal: [''],
      bancoBic: [''],
      bancoIban: [''],
      bancoCodigo: [''],
      bancoSucursal: [''],
      bancoDigitoControl: [''],
      bancoCuenta: [''],
      albaranesValorados: [''],
      facturasDesglosadas: [''],
      facturaxAlbaran: [''],
      agrupadasDireccion: [''],
      albaranEmail: [''],
      facturaEmail: [''],
      clienteFijo: [''],
      clienteFinal: [''],
      aseguradora: [''],
      facturarA: [''],
      observaciones: [''],
      bloqueado: [''],
      motivoBloqueo: [''],
      fechaBaja: [''],
      fechaExpedicion: [''],
      soporte: [''],
      fechaNacimiento: [''],
      sexo: [''],
      fechaMandato: [''],
      referenciaMandato: [''],
      cuentaContable: [''],
      idTarifa: [0],
      idTipoCliente: [0],
      mostrarLotes: [0],
      movil: [''],
      cea: [''],
      veterinario: [''],
      oficinaContable: [''],
      unidadTramitadora: [''],
      organoGestor: [''],
   });

   // public camposFormularioBloqueados = Object.keys(this.formulario.controls).filter(key => key !== 'codigo');


   constructor(
      private activatedRoute: ActivatedRoute,
      private camposVisiblesService: CamposVisiblesService,
      private clientesService: ClientesService,
      private fb: FormBuilder,
      private formasPagoService: FormasPagoService,
      private misFuncionesService: MisFuncionesService,
      private paisesService: PaisesService,
      private provinciasService: ProvinciasService,
      private regimenIvaService: RegimenIvaService,
      private router: Router,
      private titleService: Title
   ) { }

   /* -------------------------------------------------------------------------- */
   /*                      NECESARIO PARA ACTIVAR TABULADOR                      */
   /* -------------------------------------------------------------------------- */

   ngAfterViewInit(): void {
      setTimeout(() => {
         this.initTab();
      }, environment.timeOutDefecto);
   }

   initTab() {
      const controls = this.inputs.toArray();
      for (let i = 0; i < controls.length - 1; i++) {
         controls[i].nextControl = controls[i + 1].self;
      }
   }

   ngOnInit(): void {
      this.cargarCamposVisibles();
      this.cargarClientes();
      this.cargarProvincias();
      this.cargarPaises();
      this.cargarFormasPago();
      this.cargarTiposIvaCliente();
      // this.activatedRoute.params
      //         .subscribe(({id})=>{
      //           if (id > 0){
      //             this.idActivo = id;
      //             this.cargarCliente(id)}

      //         });
      if (environment.codigoClienteAutomatico) {
         this.cargarNuevoCodigo();
      }

   }


   /* -------------------------------------------------------------------------- */
   /*                             FUNCIONES DE COMBOS                            */
   /* -------------------------------------------------------------------------- */

   get nombreProvincia() {
      let nombreProvincia = '';
      this.provincias.forEach(provincia => {
         if (provincia.codigo == this.provinciaActiva) {
            nombreProvincia = provincia.nombre!;
         } else {
            if (nombreProvincia == '') {
               nombreProvincia = 'Provincia';
            }
         }
      });
      return nombreProvincia;
   }

   get nombreFormaPago() {
      let nombreFormaPago = '';
      this.formasPago.forEach(formaPago => {
         if (formaPago.id == this.formaPagoActiva) {
            nombreFormaPago = formaPago.nombre!;
         } else {
            if (nombreFormaPago == '') {
               nombreFormaPago = 'Forma Pago';
            }
         }
      });
      return nombreFormaPago;
   }

   get nombreTipoIvaCliente() {
      let nombreTipoIvaCliente = '';
      this.tiposIvaCliente.forEach(tipoIvaCliente => {
         if (tipoIvaCliente.id == this.tipoIvaClienteActivo) {
            nombreTipoIvaCliente = tipoIvaCliente.nombre!;
         } else {
            if (nombreTipoIvaCliente == '') {
               nombreTipoIvaCliente = 'Tipo Iva';
            }
         }
      });
      return nombreTipoIvaCliente;
   }

   cargarCamposVisibles() {
      this.activarSpinner = true;
      this.camposVisiblesService.getAll(this.paginaActiva, this.rows, 'manClientes', 'id', -1)
         .subscribe(
            resp => {
               if (resp.status != 200) {


               } else {
                  this.camposVisibles = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarPaises() {
      this.activarSpinner = true;
      this.paisesService.getAll('nombre')
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.paises = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarClientes() {
      this.activarSpinner = true;
      this.clientesService.getCombo('nombreFiscal')
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.clientes = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarProvincias() {
      this.activarSpinner = true;
      this.provinciasService.getAll('nombre')
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.provincias = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarFormasPago() {
      this.activarSpinner = true;
      this.formasPagoService.getAll('nombre')
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.formasPago = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarTiposIvaCliente() {
      this.activarSpinner = true;
      this.regimenIvaService.getAll('nombre')
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.tiposIvaCliente = resp.detalle;
               }
               this.activarSpinner = false;
            }
         );

   }

   /* -------------------------------------------------------------------------- */
   /*                                VALIDACIONES                                */
   /* -------------------------------------------------------------------------- */

   campoNoValido(campo: string) {
      return (this.formulario.controls[campo].errors && this.formulario.controls[campo].touched)
   }

   validarCP() {
      if (this.formulario.get('codigoPostal')) {
         this.provinciaActiva = this.formulario.get('codigoPostal')!.value.substr(0, 2);
      }

   }


   validarBancoCodigo() {
      if (this.formulario.get('bancoCodigo')!.value.length > 3) { this.domBancoSucursal?.nativeElement.focus(); }
   }

   validarBancoSucursal() {
      if (this.formulario.get('bancoSucursal')!.value.length > 3) { this.domBancoDigitoControl?.nativeElement.focus(); }
   }

   validarBancoDigitoControl() {
      if (this.formulario.get('bancoDigitoControl')!.value.length > 1) { this.domBancoCuenta?.nativeElement.focus(); }
   }

   validarBancoCuenta() {
      if (this.formulario.get('bancoCuenta')!.value.length > 9) { this.domBancoBic?.nativeElement.focus(); }
   }

   validarCliente() {
      const codigo = this.formulario.get('codigo')!.value;
      if (codigo.length > 0) {
         this.activarSpinner = true;
         this.clientesService.getClienteCodigo(codigo)
            .subscribe(
               resp => {
                  if (resp.status != 200) {
                     if (this.idActivo != 0) {
                        this.mensaje.textoAlert = resp.detalle;
                        this.mensaje.alert = true;
                     }
                  } else {
                     this.mensaje.textoAlert = "Ese Código de Cliente ya existe."
                     this.mensaje.alert = true;
                     this.resetearFormulario();
                     this.domCodigo?.nativeElement.focus();
                  }
                  this.activarSpinner = false;
               }
            );
      } else {
         if (environment.codigoClienteAutomatico) {
            this.cargarNuevoCodigo();
         }
      }
   }

   validarFacturarA() {

      if (this.formulario.get('facturarA')!.value != this.clienteActivo.facturarA) {
         if (this.formulario.get('facturarA')!.value) {
            if (this.formulario.get('facturarA')!.value.indexOf(")") > 0) {
               const codigoCliente: string = this.formulario.get('facturarA')!.value.substring(1, this.formulario.get('facturarA')!.value.indexOf(")"));
               this.actualizarFacturarAActivo(codigoCliente);

            } else {
               this.actualizarFacturarAActivo(this.formulario.get('facturarA')!.value);

            }
         }
      }
   }

   campoVisible(campo: string): boolean {
      let retorno: boolean = false;
      this.camposVisibles.forEach(control => {
         if (control.campo == campo) {
            retorno = true;
         }
      })
      return retorno;
   }

   texto(campo: string): string {
      return this.camposVisibles.find(control => { return control.campo == campo })?.texto!;
   }

   completarNombreFiscal() {

      if (this.formulario.get('nombreFiscal')!.value == '') {
         const nombreFiscal = this.formulario.get('nombre')!.value + ' ' + this.formulario.get('apellido1')!.value + ' ' + this.formulario.get('apellido2')!.value
         this.actualizarClienteActivo();
         this.setearNombreFiscal(nombreFiscal);

      }
   }

   validarDni() {

      let retorno: boolean = true;
      if (this.tipoDocumentoActivo.codigo == 'D' && this.formulario.controls['cif'].touched && this.formulario.get('cif')!.value.length > 0) {
         retorno = this.misFuncionesService.validarDni(this.formulario.get('cif')!.value)
      }
      return retorno;
   }

   get esEspana() {
      if (this.misFuncionesService.left(this.formulario.get('pais')!.value, 3).toUpperCase() != 'ESP' && this.formulario.get('pais')!.value != '') {
         return false;
      }
      return true;
   }

   /* -------------------------------------------------------------------------- */
   /*                               ACTUALIZACIONES                              */
   /* -------------------------------------------------------------------------- */

   actualizarClienteActivo() {


      this.clienteActivo.codigo = this.formulario.get('codigo')!.value;
      this.clienteActivo.nombreComercial = this.formulario.get('nombreComercial')!.value;
      this.clienteActivo.nombre = this.formulario.get('nombre')!.value;
      this.clienteActivo.apellido1 = this.formulario.get('apellido1')!.value;
      this.clienteActivo.apellido2 = this.formulario.get('apellido2')!.value;
      this.clienteActivo.fechaExpedicion = this.formulario.get('fechaExpedicion')!.value;
      this.clienteActivo.soporte = this.formulario.get('soporte')!.value;
      this.clienteActivo.fechaNacimiento = this.formulario.get('fechaNacimiento')!.value;
      this.clienteActivo.fechaMandato = this.formulario.get('fechaMandato')!.value;
      this.clienteActivo.referenciaMandato = this.formulario.get('referenciaMandato')!.value;
      this.clienteActivo.tipoDocumento = this.formulario.get('tipoDocumento')!.value;
      this.clienteActivo.sexo = this.formulario.get('sexo')!.value;
      this.clienteActivo.nombreFiscal = this.formulario.get('nombreFiscal')!.value;
      this.clienteActivo.cif = this.formulario.get('cif')!.value;
      this.clienteActivo.direccion = this.formulario.get('direccion')!.value;
      this.clienteActivo.codigoPostal = this.formulario.get('codigoPostal')!.value;
      this.clienteActivo.localidad = this.formulario.get('localidad')!.value;
      this.clienteActivo.pais = this.formulario.get('pais')!.value;
      this.clienteActivo.codigoProvincia = (this.provinciaActiva.length > 2) ? this.provinciaActiva : '';
      this.clienteActivo.idFormaPago = this.formaPagoActiva;
      this.clienteActivo.idTiposIvaCliente = this.tipoIvaClienteActivo;
      this.clienteActivo.contacto = this.formulario.get('contacto')!.value;
      this.clienteActivo.tlfPrincipal = this.formulario.get('tlfPrincipal')!.value;
      this.clienteActivo.emailPrincipal = this.formulario.get('emailPrincipal')!.value;
      this.clienteActivo.bancoBic = this.formulario.get('bancoBic')!.value;
      this.clienteActivo.bancoIban = this.formulario.get('bancoIban')!.value;
      this.clienteActivo.bancoCodigo = this.formulario.get('bancoCodigo')!.value;
      this.clienteActivo.bancoSucursal = this.formulario.get('bancoSucursal')!.value;
      this.clienteActivo.bancoDigitoControl = this.formulario.get('bancoDigitoControl')!.value;
      this.clienteActivo.bancoCuenta = this.formulario.get('bancoCuenta')!.value;
      this.clienteActivo.albaranesValorados = (this.formulario.get('albaranesValorados')!.value == true) ? 1 : 0;
      this.clienteActivo.facturasDesglosadas = (this.formulario.get('facturasDesglosadas')!.value == true) ? 1 : 0;
      this.clienteActivo.facturaxAlbaran = (this.formulario.get('facturaxAlbaran')!.value == true) ? 1 : 0;
      this.clienteActivo.agrupadasDireccion = (this.formulario.get('agrupadasDireccion')!.value == true) ? 1 : 0;
      this.clienteActivo.albaranEmail = (this.formulario.get('albaranEmail')!.value == true) ? 1 : 0;
      this.clienteActivo.facturaEmail = (this.formulario.get('facturaEmail')!.value == true) ? 1 : 0;
      this.clienteActivo.clienteFijo = (this.formulario.get('clienteFijo')!.value == true) ? 1 : 0;
      this.clienteActivo.clienteFinal = (this.formulario.get('clienteFinal')!.value == true) ? 1 : 0;
      this.clienteActivo.facturarA = this.formulario.get('facturarA')!.value;
      this.clienteActivo.observaciones = this.formulario.get('observaciones')!.value;
      this.clienteActivo.bloqueado = (this.formulario.get('bloqueado')!.value == true) ? 1 : 0;
      this.clienteActivo.motivoBloqueo = this.formulario.get('motivoBloqueo')!.value;
      this.clienteActivo.fechaBaja = this.formulario.get('fechaBaja')!.value;


   }

   actualizarFacturarAActivo(codigoCliente: string) {
      // this.clientes.forEach(cliente => {
      //   if(cliente.codigo == codigoCliente){
      //     this.facturarAActivo = cliente;
      //   }
      // });
      this.actualizarClienteActivo();
      this.clienteActivo.facturarA = codigoCliente;
      this.setearFormulario();
   }


   /* -------------------------------------------------------------------------- */
   /*                          FUNCIONES DEL FORMULARIO                          */
   /* -------------------------------------------------------------------------- */

   cargarNuevoCodigo() {
      this.clientesService.getNextClienteCodigo()
         .subscribe(
            resp => {
               if (resp.status != 200) {
                  if (this.idActivo != 0) {
                     this.mensaje.textoAlert = resp.detalle;
                     this.mensaje.alert = true;
                  }
               } else {
                  this.clienteActivo.codigo = resp.detalle;
                  this.setearCodigoCliente(this.clienteActivo.codigo!);
               }
               this.activarSpinner = false;
            }
         );

   }

   cargarCliente(id: number) {
      // this.facturarAActivo = {};
      this.activarSpinner = true;
      this.clientesService.getClienteId(id)
         .subscribe(
            resp => {

               if (resp.status != 200) {
                  this.mensaje.textoAlert = resp.detalle;
                  this.mensaje.alert = true;
               } else {
                  this.clienteActivo = resp.detalle;
                  this.cargarFormulario();
               }
               this.activarSpinner = false;
            }
         );
   }

   cargarFormulario() {

      this.formulario.reset({

         codigo: this.clienteActivo.codigo,
         fechaNacimiento: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaNacimiento)) ? null : this.clienteActivo.fechaNacimiento,
         fechaMandato: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaMandato)) ? null : this.clienteActivo.fechaMandato,
         referenciaMandato: this.clienteActivo.referenciaMandato,
         fechaExpedicion: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaExpedicion)) ? null : this.clienteActivo.fechaExpedicion,
         soporte: this.clienteActivo.soporte,
         nombre: this.clienteActivo.nombre,
         apellido1: this.clienteActivo.apellido1,
         apellido2: this.clienteActivo.apellido2,
         tipoDocumento: this.clienteActivo.tipoDocumento,
         sexo: this.clienteActivo.sexo,
         nombreFiscal: this.clienteActivo.nombreFiscal,
         nombreComercial: this.clienteActivo.nombreComercial,
         cif: this.clienteActivo.cif,
         direccion: this.clienteActivo.direccion,
         codigoPostal: this.clienteActivo.codigoPostal,
         localidad: this.clienteActivo.localidad,
         pais: this.clienteActivo.pais,
         contacto: this.clienteActivo.contacto,
         tlfPrincipal: this.clienteActivo.tlfPrincipal,
         emailPrincipal: this.clienteActivo.emailPrincipal,
         bancoBic: this.clienteActivo.bancoBic,
         bancoIban: this.clienteActivo.bancoIban,
         bancoCodigo: this.clienteActivo.bancoCodigo,
         bancoSucursal: this.clienteActivo.bancoSucursal,
         bancoDigitoControl: this.clienteActivo.bancoDigitoControl,
         bancoCuenta: this.clienteActivo.bancoCuenta,
         albaranesValorados: (this.clienteActivo.albaranesValorados == 1) ? true : false,
         facturasDesglosadas: (this.clienteActivo.facturasDesglosadas == 1) ? true : false,
         facturaxAlbaran: (this.clienteActivo.facturaxAlbaran == 1) ? true : false,
         agrupadasDireccion: (this.clienteActivo.agrupadasDireccion == 1) ? true : false,
         albaranEmail: (this.clienteActivo.albaranEmail == 1) ? true : false,
         facturaEmail: (this.clienteActivo.facturaEmail == 1) ? true : false,
         clienteFijo: (this.clienteActivo.clienteFijo == 1) ? true : false,
         clienteFinal: (this.clienteActivo.clienteFinal == 1) ? true : false,
         facturarA: this.clienteActivo.facturarA,
         observaciones: this.clienteActivo.observaciones,
         bloqueado: (this.clienteActivo.bloqueado == 1) ? true : false,
         motivoBloqueo: this.clienteActivo.motivoBloqueo,
         fechaBaja: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaBaja)) ? null : this.clienteActivo.fechaBaja,
      });
      this.provinciaActiva = this.clienteActivo.codigoProvincia!;
      this.formaPagoActiva = this.clienteActivo.idFormaPago!;
      this.tipoIvaClienteActivo = this.clienteActivo.idTiposIvaCliente!;
      this.sexoActivo = this.clienteActivo.sexo!;
      this.tipoDocumentoActivo = this.tiposDocumento.find(tipoDocumento => { return tipoDocumento.codigo == this.clienteActivo.tipoDocumento })! || this.tiposDocumento[0];
      this.tipoParentescoActivo = this.tiposParentesco.find(tipoParentesco => { return tipoParentesco.codigo == this.clienteActivo.tipoParentesco })! || this.tiposParentesco[0];

   }

   grabar() {
      if (this.formulario.valid) {
         const data: string = `id=${this.idActivo
            }&codigo=${this.formulario.get('codigo')!.value
            }&nombreComercial=${this.formulario.get('nombreComercial')!.value
            }&nombreFiscal=${this.formulario.get('nombreFiscal')!.value
            }&cif=${this.formulario.get('cif')!.value
            }&direccion=${this.formulario.get('direccion')!.value
            }&codigoPostal=${this.formulario.get('codigoPostal')!.value
            }&localidad=${this.formulario.get('localidad')!.value
            }&pais=${this.formulario.get('pais')!.value
            }&codigoProvincia=${(this.provinciaActiva.length > 2) ? 0 : this.provinciaActiva
            }&idFormaPago=${this.formaPagoActiva
            }&idTiposIvaCliente=${this.tipoIvaClienteActivo
            }&contacto=${this.formulario.get('contacto')!.value
            }&tlfPrincipal=${this.formulario.get('tlfPrincipal')!.value
            }&emailPrincipal=${this.formulario.get('emailPrincipal')!.value
            }&bancoBic=${this.formulario.get('bancoBic')!.value
            }&bancoIban=${this.formulario.get('bancoIban')!.value
            }&bancoCodigo=${this.formulario.get('bancoCodigo')!.value
            }&bancoSucursal=${this.formulario.get('bancoSucursal')!.value
            }&bancoDigitoControl=${this.formulario.get('bancoDigitoControl')!.value
            }&bancoCuenta=${this.formulario.get('bancoCuenta')!.value
            }&albaranesValorados=${(this.formulario.get('albaranesValorados')!.value == true) ? 1 : 0
            }&facturasDesglosadas=${(this.formulario.get('facturasDesglosadas')!.value == true) ? 1 : 0
            }&facturaxAlbaran=${(this.formulario.get('facturaxAlbaran')!.value == true) ? 1 : 0
            }&agrupadasDireccion=${(this.formulario.get('agrupadasDireccion')!.value == true) ? 1 : 0
            }&albaranEmail=${(this.formulario.get('albaranEmail')!.value == true) ? 1 : 0
            }&facturaEmail=${(this.formulario.get('facturaEmail')!.value == true) ? 1 : 0
            }&clienteFijo=${(this.formulario.get('clienteFijo')!.value == true) ? 1 : 0
            }&clienteFinal=${(this.formulario.get('clienteFinal')!.value == true) ? 1 : 0
            }&aseguradora=${(this.formulario.get('aseguradora')!.value == true) ? 1 : 0
            }&facturarA=${this.formulario.get('facturarA')!.value
            }&observaciones=${this.formulario.get('observaciones')!.value
            }&bloqueado=${(this.formulario.get('bloqueado')!.value == true) ? 1 : 0
            }&motivoBloqueo=${this.formulario.get('motivoBloqueo')!.value
            }&fechaBaja=${this.formulario.get('fechaBaja')!.value
            }&fechaExpedicion=${this.formulario.get('fechaExpedicion')!.value
            }&soporte=${this.formulario.get('soporte')!.value
            }&fechaNacimiento=${this.formulario.get('fechaNacimiento')!.value
            }&fechaMandato=${this.formulario.get('fechaMandato')!.value
            }&movil=${this.formulario.get('movil')!.value
            }&cea=${this.formulario.get('cea')!.value
            }&colegiado=${this.formulario.get('veterinario')!.value
            }&idVeterinario=0
         &referenciaMandato=${this.formulario.get('referenciaMandato')!.value
            }&cuentaContable=${this.formulario.get('cuentaContable')!.value
            }&nombre=${this.formulario.get('nombre')!.value
            }&apellido1=${this.formulario.get('apellido1')!.value
            }&apellido2=${this.formulario.get('apellido2')!.value
            }&tipoDocumento=${this.tipoDocumentoActivo.codigo
            }&tipoParentesco=${this.tipoParentescoActivo.codigo
            }&sexo=${this.sexoActivo
            }&idTipoCliente=1
         &idRuta=undefined
         &idTarifa=undefined
         &tipoPersona=undefined
         }&oficinaContable=${this.formulario.get('oficinaContable')!.value
            }&unidadTramitadora=${this.formulario.get('unidadTramitadora')!.value
            }&organoGestor=${this.formulario.get('organoGestor')!.value
            }&mostrarLotes=${(this.formulario.get('mostrarLotes')!.value) ? 1 : 0
            }`;
         this.activarSpinner = true;

         if (this.idActivo == 0) {

            // CREAR NUEVO CLIENTE 
            this.clientesService.nuevo(data)
               .subscribe(
                  resp => {
                     if (resp.status != 200) {
                        this.mensaje.textoAlert = resp.detalle;
                        this.mensaje.alert = true;
                     } else {
                        this.mensaje.textoSuccess = resp.detalle;
                        this.mensaje.success = true;
                        this.idActivo = resp.ultimoId;
                        // if (environment.moduloCamping){
                        //   window.history.back();
                        // }else{
                        // if (environment.nuevoAlGrabar == true){this.irClienteNuevo();}
                        // (environment.nuevoAlGrabar == true)?this.irClienteNuevo() : this.router.navigateByUrl(`/mantenimientos/manClientes/${this.idActivo}`);
                        this.onCodigo.emit(this.formulario.get('codigo')!.value);
                        // console.log(this.clienteActivo.cif);

                        this.onCif.emit(this.formulario.get('cif')!.value);
                        this.formulario.markAsPristine();
                        // }
                     }
                     this.activarSpinner = false;
                  }
               );
         }
         // else{
         //   // NO ACTUALIZAR CLIENTE 
         //   this.clientesService.actualizar(data, this.idActivo)
         //                       .subscribe(
         //                         resp => {
         //                           if(resp.status != 200) {
         //                             this.mensaje.textoAlert = resp.detalle;
         //                             this.mensaje.alert = true;
         //                           }else {
         //                             this.mensaje.textoSuccess = resp.detalle;
         //                             this.mensaje.success = true;
         //                             this.formulario.markAsPristine();
         //                             if (environment.nuevoAlGrabar == true){this.irClienteNuevo();}
         //                           }  
         //                           this.activarSpinner = false;
         //                         }
         //                       );
         // }
      } else {
         this.mensaje.textoAlert = 'Errores en el formulario. Revise los campos.';
         this.mensaje.alert = true;
         return Object.values(this.formulario.controls).forEach(control => {
            if (control instanceof FormGroup) {
               return Object.values(control.controls).forEach(control => { control.markAsTouched() })
            } else {
               control.markAsTouched();
            }
         });
      }
   }

   irClienteNuevo(comprobarCambios: boolean = false) {

      if (this.formulario.touched && comprobarCambios) {
         this.mensaje.textoDanger = 'Se han detectado cambios en el formulario ¿Desea salir sin grabar?'
         this.mensaje.persistente = true;
         this.mensaje.botones = true;
         this.mensaje.comandoCancelar = 'this.mensaje.danger = false;';
         this.mensaje.comandoAceptar = 'this.resetearFormulario(); this.router.navigateByUrl(`/mantenimientos/manClientes/0`);';
         this.mensaje.danger = true;
      } else {
         this.resetearFormulario();
         this.router.navigateByUrl(`/mantenimientos/manClientes/0`);
      }

   }



   resetearFormulario() {
      this.formulario.reset({
         codigo: '',
         fechaExpedicion: null,
         soporte: '',
         fechaNacimiento: null,
         fechaMandato: null,
         referenciaMandato: '',
         nombre: '',
         apellido1: '',
         apellido2: '',
         sexo: '',
         tipoDocumento: '',
         nombreFiscal: '',
         nombreComercial: '',
         cif: '',
         direccion: '',
         codigoPostal: '',
         localidad: '',
         pais: '',
         contacto: '',
         tlfPrincipal: '',
         emailPrincipal: '',
         bancoBic: '',
         bancoIban: '',
         bancoCodigo: '',
         bancoSucursal: '',
         bancoDigitoControl: '',
         bancoCuenta: '',
         albaranesValorados: false,
         facturasDesglosadas: false,
         facturaxAlbaran: false,
         agrupadasDireccion: false,
         albaranEmail: false,
         facturaEmail: false,
         clienteFijo: false,
         clienteFinal: false,
         facturarA: '',
         observaciones: '',
         bloqueado: false,
         motivoBloqueo: '',
         fechaBaja: null,
      });
      this.idActivo = 0;
      this.clienteActivo = {};
      // this.facturarAActivo = {};
      this.sexoActivo = this.sexos[0];
      this.tipoDocumentoActivo = this.tiposDocumento[0];
      this.tipoParentescoActivo = this.tiposParentesco[0];

      this.provinciaActiva = "Provincia";

      this.formaPagoActiva = 1;

      this.tipoIvaClienteActivo = 1;
   }

   setearFormulario() {
      this.formulario.reset({
         codigo: (this.clienteActivo.codigo) ? this.clienteActivo.codigo : '',
         fechaExpedicion: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaExpedicion)) ? null : this.clienteActivo.fechaExpedicion,
         soporte: (this.clienteActivo.soporte) ? this.clienteActivo.soporte : '',
         fechaNacimiento: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaNacimiento)) ? null : this.clienteActivo.fechaNacimiento,
         fechaMandato: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaMandato)) ? null : this.clienteActivo.fechaMandato,
         referenciaMandato: (this.clienteActivo.referenciaMandato) ? this.clienteActivo.referenciaMandato : '',
         nombre: (this.clienteActivo.nombre) ? this.clienteActivo.nombre : '',
         apellido1: (this.clienteActivo.apellido1) ? this.clienteActivo.apellido1 : '',
         apellido2: (this.clienteActivo.apellido2) ? this.clienteActivo.apellido2 : '',
         tipoDocumento: (this.clienteActivo.tipoDocumento) ? this.clienteActivo.tipoDocumento : '',
         sexo: (this.clienteActivo.sexo) ? this.clienteActivo.sexo : '',
         nombreFiscal: (this.clienteActivo.nombreFiscal) ? this.clienteActivo.nombreFiscal : '',
         nombreComercial: (this.clienteActivo.nombreComercial) ? this.clienteActivo.nombreComercial : '',
         cif: (this.clienteActivo.cif) ? this.clienteActivo.cif : '',
         direccion: (this.clienteActivo.direccion) ? this.clienteActivo.direccion : '',
         codigoPostal: (this.clienteActivo.codigoPostal) ? this.clienteActivo.codigoPostal : '',
         localidad: (this.clienteActivo.localidad) ? this.clienteActivo.localidad : '',
         pais: (this.clienteActivo.pais) ? this.clienteActivo.pais : '',
         contacto: (this.clienteActivo.contacto) ? this.clienteActivo.contacto : '',
         tlfPrincipal: (this.clienteActivo.tlfPrincipal) ? this.clienteActivo.tlfPrincipal : '',
         emailPrincipal: (this.clienteActivo.emailPrincipal) ? this.clienteActivo.emailPrincipal : '',
         bancoBic: (this.clienteActivo.bancoBic) ? this.clienteActivo.bancoBic : '',
         bancoIban: (this.clienteActivo.bancoIban) ? this.clienteActivo.bancoIban : '',
         bancoCodigo: (this.clienteActivo.bancoCodigo) ? this.clienteActivo.bancoCodigo : '',
         bancoSucursal: (this.clienteActivo.bancoSucursal) ? this.clienteActivo.bancoSucursal : '',
         bancoDigitoControl: (this.clienteActivo.bancoDigitoControl) ? this.clienteActivo.bancoDigitoControl : '',
         bancoCuenta: (this.clienteActivo.bancoCuenta) ? this.clienteActivo.bancoCuenta : '',
         albaranesValorados: (this.clienteActivo.albaranesValorados == 1) ? true : false,
         facturasDesglosadas: (this.clienteActivo.facturasDesglosadas == 1) ? true : false,
         facturaxAlbaran: (this.clienteActivo.facturaxAlbaran == 1) ? true : false,
         agrupadasDireccion: (this.clienteActivo.agrupadasDireccion == 1) ? true : false,
         albaranEmail: (this.clienteActivo.albaranEmail == 1) ? true : false,
         facturaEmail: (this.clienteActivo.facturaEmail == 1) ? true : false,
         clienteFijo: (this.clienteActivo.clienteFijo == 1) ? true : false,
         clienteFinal: (this.clienteActivo.clienteFinal == 1) ? true : false,
         facturarA: (this.clienteActivo.facturarA) ? this.clienteActivo.facturarA : '',
         observaciones: (this.clienteActivo.observaciones) ? this.clienteActivo.observaciones : '',
         bloqueado: (this.clienteActivo.bloqueado == 1) ? true : false,
         motivoBloqueo: (this.clienteActivo.motivoBloqueo) ? this.clienteActivo.motivoBloqueo : '',
         fechaBaja: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaBaja)) ? null : this.clienteActivo.fechaBaja,
      });

   }

   setearNombreFiscal(nombreFiscal: string) {
      this.formulario.reset({
         codigo: (this.clienteActivo.codigo) ? this.clienteActivo.codigo : '',
         nombre: (this.clienteActivo.nombre) ? this.clienteActivo.nombre : '',
         apellido1: (this.clienteActivo.apellido1) ? this.clienteActivo.apellido1 : '',
         apellido2: (this.clienteActivo.apellido2) ? this.clienteActivo.apellido2 : '',
         tipoDocumento: (this.clienteActivo.tipoDocumento) ? this.clienteActivo.tipoDocumento : '',
         sexo: (this.clienteActivo.sexo) ? this.clienteActivo.sexo : '',
         nombreFiscal: nombreFiscal,
         nombreComercial: (this.clienteActivo.nombreComercial) ? this.clienteActivo.nombreComercial : '',
         cif: (this.clienteActivo.cif) ? this.clienteActivo.cif : '',
         direccion: (this.clienteActivo.direccion) ? this.clienteActivo.direccion : '',
         codigoPostal: (this.clienteActivo.codigoPostal) ? this.clienteActivo.codigoPostal : '',
         localidad: (this.clienteActivo.localidad) ? this.clienteActivo.localidad : '',
         pais: (this.clienteActivo.pais) ? this.clienteActivo.pais : '',
         contacto: (this.clienteActivo.contacto) ? this.clienteActivo.contacto : '',
         tlfPrincipal: (this.clienteActivo.tlfPrincipal) ? this.clienteActivo.tlfPrincipal : '',
         emailPrincipal: (this.clienteActivo.emailPrincipal) ? this.clienteActivo.emailPrincipal : '',
         bancoBic: (this.clienteActivo.bancoBic) ? this.clienteActivo.bancoBic : '',
         bancoIban: (this.clienteActivo.bancoIban) ? this.clienteActivo.bancoIban : '',
         bancoCodigo: (this.clienteActivo.bancoCodigo) ? this.clienteActivo.bancoCodigo : '',
         bancoSucursal: (this.clienteActivo.bancoSucursal) ? this.clienteActivo.bancoSucursal : '',
         bancoDigitoControl: (this.clienteActivo.bancoDigitoControl) ? this.clienteActivo.bancoDigitoControl : '',
         bancoCuenta: (this.clienteActivo.bancoCuenta) ? this.clienteActivo.bancoCuenta : '',
         albaranesValorados: (this.clienteActivo.albaranesValorados == 1) ? true : false,
         facturasDesglosadas: (this.clienteActivo.facturasDesglosadas == 1) ? true : false,
         facturaxAlbaran: (this.clienteActivo.facturaxAlbaran == 1) ? true : false,
         agrupadasDireccion: (this.clienteActivo.agrupadasDireccion == 1) ? true : false,
         albaranEmail: (this.clienteActivo.albaranEmail == 1) ? true : false,
         facturaEmail: (this.clienteActivo.facturaEmail == 1) ? true : false,
         clienteFijo: (this.clienteActivo.clienteFijo == 1) ? true : false,
         clienteFinal: (this.clienteActivo.clienteFinal == 1) ? true : false,
         facturarA: (this.clienteActivo.facturarA) ? this.clienteActivo.facturarA : '',
         observaciones: (this.clienteActivo.observaciones) ? this.clienteActivo.observaciones : '',
         bloqueado: (this.clienteActivo.bloqueado == 1) ? true : false,
         motivoBloqueo: (this.clienteActivo.motivoBloqueo) ? this.clienteActivo.motivoBloqueo : '',
         fechaBaja: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaBaja)) ? null : this.clienteActivo.fechaBaja,
         fechaExpedicion: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaExpedicion)) ? null : this.clienteActivo.fechaExpedicion,
         soporte: (this.clienteActivo.soporte) ? this.clienteActivo.soporte : '',
         fechaNacimiento: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaNacimiento)) ? null : this.clienteActivo.fechaNacimiento,
         fechaMandato: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaMandato)) ? null : this.clienteActivo.fechaMandato,
         referenciaMandato: (this.clienteActivo.referenciaMandato) ? this.clienteActivo.referenciaMandato : '',

      });

      this.formulario.markAsDirty();

   }

   setearCodigoCliente(codigo: string) {
      this.formulario.reset({
         codigo: codigo,
         nombre: (this.clienteActivo.nombre) ? this.clienteActivo.nombre : '',
         apellido1: (this.clienteActivo.apellido1) ? this.clienteActivo.apellido1 : '',
         apellido2: (this.clienteActivo.apellido2) ? this.clienteActivo.apellido2 : '',
         tipoDocumento: (this.clienteActivo.tipoDocumento) ? this.clienteActivo.tipoDocumento : '',
         sexo: (this.clienteActivo.sexo) ? this.clienteActivo.sexo : '',
         nombreFiscal: (this.clienteActivo.nombreFiscal) ? this.clienteActivo.nombreFiscal : '',
         nombreComercial: (this.clienteActivo.nombreComercial) ? this.clienteActivo.nombreComercial : '',
         cif: (this.clienteActivo.cif) ? this.clienteActivo.cif : '',
         direccion: (this.clienteActivo.direccion) ? this.clienteActivo.direccion : '',
         codigoPostal: (this.clienteActivo.codigoPostal) ? this.clienteActivo.codigoPostal : '',
         localidad: (this.clienteActivo.localidad) ? this.clienteActivo.localidad : '',
         pais: (this.clienteActivo.pais) ? this.clienteActivo.pais : '',
         contacto: (this.clienteActivo.contacto) ? this.clienteActivo.contacto : '',
         tlfPrincipal: (this.clienteActivo.tlfPrincipal) ? this.clienteActivo.tlfPrincipal : '',
         emailPrincipal: (this.clienteActivo.emailPrincipal) ? this.clienteActivo.emailPrincipal : '',
         bancoBic: (this.clienteActivo.bancoBic) ? this.clienteActivo.bancoBic : '',
         bancoIban: (this.clienteActivo.bancoIban) ? this.clienteActivo.bancoIban : '',
         bancoCodigo: (this.clienteActivo.bancoCodigo) ? this.clienteActivo.bancoCodigo : '',
         bancoSucursal: (this.clienteActivo.bancoSucursal) ? this.clienteActivo.bancoSucursal : '',
         bancoDigitoControl: (this.clienteActivo.bancoDigitoControl) ? this.clienteActivo.bancoDigitoControl : '',
         bancoCuenta: (this.clienteActivo.bancoCuenta) ? this.clienteActivo.bancoCuenta : '',
         albaranesValorados: (this.clienteActivo.albaranesValorados == 1) ? true : false,
         facturasDesglosadas: (this.clienteActivo.facturasDesglosadas == 1) ? true : false,
         facturaxAlbaran: (this.clienteActivo.facturaxAlbaran == 1) ? true : false,
         agrupadasDireccion: (this.clienteActivo.agrupadasDireccion == 1) ? true : false,
         albaranEmail: (this.clienteActivo.albaranEmail == 1) ? true : false,
         facturaEmail: (this.clienteActivo.facturaEmail == 1) ? true : false,
         clienteFijo: (this.clienteActivo.clienteFijo == 1) ? true : false,
         clienteFinal: (this.clienteActivo.clienteFinal == 1) ? true : false,
         facturarA: (this.clienteActivo.facturarA) ? this.clienteActivo.facturarA : '',
         observaciones: (this.clienteActivo.observaciones) ? this.clienteActivo.observaciones : '',
         bloqueado: (this.clienteActivo.bloqueado == 1) ? true : false,
         motivoBloqueo: (this.clienteActivo.motivoBloqueo) ? this.clienteActivo.motivoBloqueo : '',
         fechaBaja: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaBaja)) ? null : this.clienteActivo.fechaBaja,
         fechaExpedicion: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaExpedicion)) ? null : this.clienteActivo.fechaExpedicion,
         soporte: (this.clienteActivo.soporte) ? this.clienteActivo.soporte : '',
         fechaNacimiento: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaNacimiento)) ? null : this.clienteActivo.fechaNacimiento,
         fechaMandato: (!this.misFuncionesService.esFechaValida(this.clienteActivo.fechaMandato)) ? null : this.clienteActivo.fechaMandato,
         referenciaMandato: (this.clienteActivo.referenciaMandato) ? this.clienteActivo.referenciaMandato : '',
      });

   }

   volver() {
      window.history.back();
   }

   controlBotones(evento: number) {
      this.mensaje.botones = false;
      this.mensaje.persistente = false;
      this.mensaje.danger = false;
      if (evento == 1) {
         eval(this.mensaje.comandoAceptar!);
      } else {
         eval(this.mensaje.comandoCancelar!);
      }
   }

   irFichaColor() {
      if (this.idActivo != 0) { this.misFuncionesService.openNewTab(`peluqueria/fichasColor/${this.idActivo}`); }
   }


   cancelar() {
      this.onCancel.emit(0);
   }

   /* -------------------------------------------------------------------------- */
   /*                          FUNCIONES DE COMPONENTES                          */
   /* -------------------------------------------------------------------------- */

   formatearDni(documento: Documento) {

      this.activarOcr = false;
      // console.log('documento: ', documento);
      this.clienteActivo.apellido1 = documento.apellido1;
      this.clienteActivo.apellido2 = documento.apellido2;
      if (documento.tipoDocumento == 'IDE') { this.tipoDocumentoActivo = { codigo: 'D', descripcion: 'DNI' } };
      if (documento.tipoDocumento == 'P') { this.tipoDocumentoActivo = { codigo: 'P', descripcion: 'Pasaporte' } };
      this.clienteActivo.cif = documento.dni;
      if (this.misFuncionesService.esFechaValida(this.misFuncionesService.fechaaString(documento.fechaNacimiento!))) { this.clienteActivo.fechaNacimiento = this.misFuncionesService.fechaaString(documento.fechaNacimiento!); }
      if (this.misFuncionesService.esFechaValida(this.misFuncionesService.fechaaString(documento.fechaEmision!))) { this.clienteActivo.fechaExpedicion = this.misFuncionesService.fechaaString(documento.fechaEmision!); }
      this.clienteActivo.nombre = documento.nombre;
      this.clienteActivo.pais = documento.pais;
      this.sexoActivo = documento.sexo!;
      this.clienteActivo.nombreFiscal = this.clienteActivo.nombre + ' ' + this.clienteActivo.apellido1 + ' ' + this.clienteActivo.apellido2;
      // this.cargarNuevoCodigo();
      this.clienteActivo.codigo = documento.dni;
      this.setearCodigoCliente(this.clienteActivo.codigo!);
   }

   desdeTlf() {
      this.irClienteNuevo();
      this.activarOcr = true;
   }

   claseContenedor() {
      if (this.formulario.dirty) { return 'buscador traerAlFrente bordeContenedorRojo '; }
      return 'buscador fade-in-image traerAlFrente '
      // if (this.formulario.dirty) {return 'container fondoFormulario bordeContenedorRojo';}
      // return 'container fondoFormulario bordeContenedor'
   }

   claseH2() {
      if (this.formulario.dirty) { return 'rojo'; }
      return '';
   }


}
