import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FacturaVenta } from '../interfaces/facturaVenta.interface';
import { MisFuncionesService } from './mis-funciones.service';
import { ReenvioAEAT } from '../interfaces/reenvioAEAT.interface';

const base_url = `${environment.base_url}/Verifactu`;

@Injectable({
   providedIn: 'root'
})
export class VerifactuService {
   token: string = localStorage.getItem('tp-Token') || '';
   headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Tp-Token': localStorage.getItem('tp-Token') || ''
   });

   constructor(
               private http: HttpClient,
               private misFuncionesService: MisFuncionesService,
            ) { }

   getUltimoId() {
      const url = `${base_url}/getUltimoId`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   validarP(data: FacturaVenta) {
      const url = `${base_url}/validarP`;

      return this.http.post(url, data, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, detalle: resp.detalle, ultimoId: resp.totalPaginas };
                  return respuesta;
               }
            )
         )
         ;
   }

   generarEnvio() {
      const data: FacturaVenta = {};
      data.clave = localStorage.getItem('tc-Token') || '';
      localStorage.removeItem('proximoEnvio');
      const url = `${base_url}/generarEnvio`;
      console.log('Enviamos');
      
      return this.http.post(url, data, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, detalle: resp.detalle, ultimoId: resp.totalPaginas };
                  return respuesta;
               }
            )
         )
         ;
   }


   getFacturasRechazadas(pagina: number, rows: number, orden: string, desc: number) {
      const url = `${base_url}/getFacturasRechazadas?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   getRegistroEnvios(desdeFecha: Date, hastaFecha: Date, pagina: number, rows: number, orden: string, desc: number) {
      const url = `${base_url}/getRegistroEnvios/${desdeFecha}/${hastaFecha}?page=${pagina}&rows=${rows}&order=${orden}&desc=${desc}`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   mEcho(tiempo: number) {
      const url = `${base_url}/mEcho/${tiempo}`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   preEnvio(consulta: number = 0) {
      const url = `${base_url}/preEnvio/${consulta}`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   comprobarEnviosPendientesAEAT() {
      // console.log('Compruebo Envío:  ', this.misFuncionesService.horaActualFormatoUnix());
      const proximoEnvio: number = Number(localStorage.getItem('proximoEnvio'));
      if (proximoEnvio) {
         if (this.misFuncionesService.horaActualFormatoUnix() > proximoEnvio) {
            // console.log('Hay que enviar');
            this.generarEnvio().subscribe();
            return 200;
         } else {
            // console.log('Hay que esperar');
            return 401;
         }
      }
      // console.log('Nada que enviar');
      return 204;
   }

   descargarRespuestaAEAT(id: number) {

      const url = `${environment.base_url}/Verifactu/descargarRespuestaAEAT/${id}`;

      return this.http.get(url, { headers: this.headers, responseType: 'text' })
         .subscribe(
            (resp: string) => {
               let blob = new Blob([resp], { type: 'application/xml' });
               let dwldLink = document.createElement("a");
               let url = URL.createObjectURL(blob);
               dwldLink.setAttribute("href", url);
               dwldLink.setAttribute("download", "verifactu.xml");
               dwldLink.style.visibility = "hidden";
               document.body.appendChild(dwldLink);
               dwldLink.click();
               document.body.removeChild(dwldLink);
            },
            error => {
               console.error("Error al descargar el XML:", error);
            }
         );
   }

   descargarXMLVerifactu(id: number) {

      const url = `${environment.base_url}/Verifactu/descargarXML/${id}`;

      return this.http.get(url, { headers: this.headers, responseType: 'text' })
         .subscribe(
            (resp: string) => {
               let blob = new Blob([resp], { type: 'application/xml' });
               let dwldLink = document.createElement("a");
               let url = URL.createObjectURL(blob);
               dwldLink.setAttribute("href", url);
               dwldLink.setAttribute("download", "verifactu.xml");
               dwldLink.style.visibility = "hidden";
               document.body.appendChild(dwldLink);
               dwldLink.click();
               document.body.removeChild(dwldLink);
            },
            error => {
               console.error("Error al descargar el XML:", error);
            }
         );

   }

   comprobarErroresEnVerifactu() {
      const url = `${base_url}/comprobarErroresEnVerifactu`;
      return this.http.get(url, { headers: this.headers })
         .pipe(
            map(
               (resp: any) => {
                  const respuesta = { status: resp.status, totalPaginas: resp.totalPaginas, detalle: resp.detalle };
                  return respuesta;
               }
            )
         )
         ;
   }

   reenvioAEAT(data:ReenvioAEAT[]){
       const url = `${ base_url }/reenvioAEAT`;

       
       return this.http.post(url, data, {headers: this.headers})
                       .pipe(
                         map(
                           (resp:any)=>{
                             const respuesta = {status:resp.status, detalle:resp.detalle, ultimoId: resp.totalPaginas};
                             return respuesta;
                           }
                         )
                       )
                       ;
     }
}
